import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPhoneAlt, faEnvelope } from "@fortawesome/free-solid-svg-icons"
import {
  faTwitterSquare,
  faFacebookSquare,
  faInstagram,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons"
import Logo from "../images/Savage_Outdoor_Defense_Logo_White.png"
import CallToAction from "./CallToAction"
const toggleMenu = () => {
  document.getElementById("nav-content").classList.toggle("hidden")
  document.getElementById("burger").classList.toggle("hidden")
  document.getElementById("cross").classList.toggle("hidden")
}

const toggleNavItem = on => {
  let navItem = document.querySelector("#course-menu")
  if (on === true) {
    navItem.classList.remove("opacity-0", "scale-95", "hidden")
    navItem.classList.add("opacity-100", "scale-100")
  } else {
    navItem.classList.remove("opacity-100", "scale-100")
    navItem.classList.add("opacity-0", "scale-95", "hidden")
  }
  return
}

const Header = () => (
  <>
    <div className="relative py-4 mx-auto text-center bg-gray-900 xs:hidden sm:hidden md:px-4 lg:px-4 xl:px-4">
      <span className="absolute left-0 justify-center p-1 m-1 my-auto">
        <a
          href="https://Fb.me/Savageoutdoordefense"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faFacebookSquare}
            className="max-w-xs m-1 text-red-500 fa-lg"
          />
        </a>
        <a
          href="https://twitter.com/SavageOutAndDef"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faTwitterSquare}
            className="max-w-xs m-1 text-red-500 fa-lg"
          />
        </a>
        <a
          href="https://instagram.com/savage.outdoor.defense?igshid=195ehpl2eiuzb"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faInstagram}
            className="max-w-xs m-1 text-red-500 fa-lg"
          />
        </a>
        <a
          href="https://www.youtube.com/channel/UCnQ9Q8wu8lRKO2QMbdLQk0g"
          target="_blank"
          rel="noopener noreferrer"
        >
          <FontAwesomeIcon
            icon={faYoutubeSquare}
            className="max-w-xs m-1 text-red-500 fa-lg"
          />
        </a>
      </span>
      <CallToAction />
      <span className="absolute right-0 justify-center p-1 m-1">
        <a
          className="p-1 m-1 text-red-500 rounded hover:text-white hover:bg-red-500"
          href="tel: 979-429-3414"
        >
          <FontAwesomeIcon icon={faPhoneAlt} />
          <span> Phone</span>
        </a>
        <a
          className="p-1 m-1 text-red-500 rounded hover:text-white hover:bg-red-500"
          href="mailto:support@savageoutdoordefense.com"
        >
          <FontAwesomeIcon icon={faEnvelope} />
          <span> Email</span>
        </a>
      </span>
    </div>

    <nav data-todo-x-data="{ open: true }" className="bg-blue-800">
      <div className="flex items-center h-16 xs:justify-between sm:justify-between">
        <Link to="/">
          <img
            src={Logo}
            alt="Savage Outdoor & Defense"
            width="145"
            class="mx-auto inline-block my-auto mr-auto  md:hidden lg:hidden xl:hidden xs:ml-5 sm:ml-10"
          />
        </Link>
        <div className="flex items-center content-center flex-shrink-0">
          <div className="flex items-center content-center flex-shrink-0 xs:ml-5 sm:ml-10">
            <Link to="/">
              <img
                src={Logo}
                alt="Savage Outdoor & Defense"
                width="145"
                id="real-logo"
                class="mx-auto inline-block my-auto mr-auto  cd:mr-100px cd:ml-20 xs:hidden sm:hidden ml-5"
              />
            </Link>
          </div>

          <div className="flex content-end justify-end px-4 py-1 sm:px-6 lg:px-8">
            <div className=" xs:ml-20 sm:ml-36 md:hidden lg:hidden xl:hidden sm:block">
              <button
                onClick={() => toggleMenu()}
                className="inline-flex items-center justify-center p-2 text-blue-400 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:bg-blue-700 focus:text-white"
              >
                <svg
                  className="w-6 h-6"
                  stroke="currentColor"
                  fill="none"
                  viewBox="0 0 24 24"
                >
                  <path
                    id="burger"
                    className="inline-flex"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M4 6h16M4 12h16M4 18h16"
                  ></path>
                  <path
                    className="hidden"
                    id="cross"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                    stroke-width="2"
                    d="M6 18L18 6M6 6l12 12"
                  ></path>
                </svg>
              </button>
            </div>
          </div>
          <div className="hidden md:block lg:block xl:block">
            <div className="flex items-baseline">
              <div onMouseOver={() => toggleNavItem(false)}>
                <Link
                  to="/"
                  className="px-3 py-2 ml-4 text-sm font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >
                  Home
                </Link>
                <Link
                  to="/outdoor-shooting-range"
                  className="px-3 py-2 ml-4 text-sm font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >
                  Public Gun Range{" "}
                </Link>
              </div>
              <div class="relative inline-block text-left z-50">
                <div>
                  <Link
                    onMouseOver={() => toggleNavItem(true)}
                    to="/courses"
                    className="px-3 py-2 ml-4 text-sm font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                  >
                    Courses
                  </Link>
                </div>
                <div
                  id="course-menu"
                  class="hidden origin-top-right absolute left-5 mt-2 w-56 rounded shadow transition ease-out duration-100 transform opacity-0 scale-95"
                >
                  <div
                    onMouseLeave={() => toggleNavItem(false)}
                    class="rounded border-b-1 blue-100 bg-white shadow-xs"
                  >
                    <Link to="/texas-license-to-carry-ltc-course">
                      <span class="border-b-2 border-gray-50 block rounded px-4 py-2 text-sm font-medium leading-5 text-blue-700 hover:font-bold hover:bg-gray-50">
                        Texas LTC Course
                      </span>
                    </Link>
                    <Link to="/course-of-fire">
                      <span class="border-b-2 border-gray-50 block rounded px-4 py-2 text-sm font-medium leading-5 text-blue-700 hover:font-bold hover:bg-gray-50">
                        LTC Course of Fire
                      </span>
                    </Link>
                    <Link to="/basics-of-pistol-shooting-bop">
                      <span class="border-b-2 border-gray-50 block rounded px-4 py-2 text-sm font-medium leading-5 text-blue-700 hover:font-bold hover:bg-gray-50">
                        Basics of Pistol Shooting (BOP)
                      </span>
                    </Link>
                    <Link to="/intro-to-pistols-weekend">
                      <span class="border-b-2 border-gray-50 block rounded px-4 py-2 text-sm font-medium leading-5 text-blue-700 hover:font-bold hover:bg-gray-50">
                        Intro to Pistols Weekend
                      </span>
                    </Link>

                    <Link to="/private-and-group-general-firearm-instruction">
                      <span class="border-b-2 border-gray-50 block rounded px-4 py-2 text-sm font-medium leading-5 text-blue-700 hover:font-bold hover:bg-gray-50">
                        Private Instruction
                      </span>
                    </Link>
                  </div>
                </div>
              </div>
              <div className="ml-4 rainbow-wrapper">
                <Link
                  to="/events"
                  className="px-3 py-2 text-sm font-medium link-button"
                >
                  Kurten Fireworks
                </Link>
              </div>
              <Link
                to="/Machine-Gun-Days"
                className="px-3 py-2 ml-4 text-sm font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
              >
                Machine Gun Days
              </Link>
              <div onMouseOver={() => toggleNavItem(false)}>
                <Link
                  to="/faq"
                  className="px-3 py-2 ml-4 text-sm font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >
                  FAQ
                </Link>

                <Link
                  to="/about-us"
                  className="px-3 py-2 ml-4 text-sm font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >
                  About Us
                </Link>
                <Link
                  to="/contact"
                  className="px-3 py-2 ml-4 text-sm font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >
                  Contact Us
                </Link>

                <Link
                  to="/gallery"
                  className="px-3 py-2 ml-4 text-sm font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
                >
                  Gallery
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="flex items-center h-16 md:hidden lg:hidden xl:hidden xs:justify-between sm:justify-between">
        <div className="w-full ml-2 rainbow-wrapper">
          <Link
            to="/events"
            className="hidden px-3 py-2 text-sm font-medium link-button"
          >
            21st Annual Kurten Fireworks Show & Fundraiser! (click here for
            details)
          </Link>
        </div>
      </div>
      <div>
        <div id="nav-content" className="hidden px-2 pt-2 pb-3 sm:px-3">
          <Link
            to="/"
            className="block px-3 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
          >
            Home
          </Link>
          <Link
            to="/outdoor-shooting-range"
            className="block px-3 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
          >
            Public Gun Range
          </Link>
          <Link
            to="/courses"
            className="block px-3 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
          >
            Courses
          </Link>
          <div className="ml-2 rainbow-wrapper">
            <Link
              to="/events"
              className="px-3 py-2 text-sm font-medium link-button"
            >
              Kurten Fireworks
            </Link>
          </div>
          <Link
            to="/Machine-Gun-Days"
            className="block px-3 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
          >
            Machine Gun Days
          </Link>
          <Link
            to="/faq"
            className="block px-3 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
          >
            FAQ
          </Link>
          <Link
            to="/about-us"
            className="block px-3 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
          >
            About Us
          </Link>
          <Link
            to="/contact"
            className="block px-3 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
          >
            Contact Us
          </Link>
          <Link
            to="/gallery"
            className="block px-3 py-2 text-base font-medium text-blue-100 rounded-md hover:text-white hover:bg-blue-700 focus:outline-none focus:text-white focus:bg-blue-700"
          >
            Gallery
          </Link>
        </div>
      </div>
    </nav>
  </>
)

Header.propTypes = {
  siteTitle: PropTypes.string,
}

Header.defaultProps = {
  siteTitle: ``,
}

export default Header

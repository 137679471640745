import React from "react"
import { StaticQuery, graphql, Link } from "gatsby"
export default function Header() {
  return (
    <StaticQuery
      query={graphql`
        query ctaQuery {
          allMarkdownRemark(
            filter: { frontmatter: { title: { eq: "Top Bar CTA" } } }
          ) {
            edges {
              node {
                id
                frontmatter {
                  title
                  cta_text
                  cta_link
                }
              }
            }
          }
        }
      `}
      render={data => (
        <div
          className="p-2 bg-blue-800 items-center text-blue-100 leading-none sm:rounded-full md:rounded-full lg:rounded-full xl:rounded-full flex sm:inline-flex md:inline-flex lg:inline-flex xl:inline-flex"
          role="alert"
        >
          <span className="flex rounded-full bg-blue-500 uppercase px-2 py-1 text-xs font-bold mr-3">
            New
          </span>
          <span className="font-semibold mr-2 text-left flex-auto">
            <a href={data.allMarkdownRemark.edges[0].node.frontmatter.cta_link}>
              {data.allMarkdownRemark.edges[0].node.frontmatter.cta_text}
            </a>
          </span>
          <svg
            className="fill-current opacity-75 h-4 w-4"
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 20 20"
          >
            <path d="M12.95 10.707l.707-.707L8 4.343 6.586 5.757 10.828 10l-4.242 4.243L8 15.657l4.95-4.95z" />
          </svg>
        </div>
      )}
    />
  )
}

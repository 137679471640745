import { Link } from "gatsby"
import React from "react"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faTwitterSquare,
  faFacebookSquare,
  faInstagram,
  faYoutubeSquare,
} from "@fortawesome/free-brands-svg-icons"

const Footer = () => {
  return (
    <div className="bg-gray-900 mt-20">
      <div className="py-12 px-4 overflow-hidden sm:px-6 lg:px-8">
        <nav className="mx-5 my-2 flex flex-wrap justify-center">
          <div className="px-5 py-2">
            <Link
              to="/"
              className="text-base leading-6 text-red-500 hover:text-red-900"
            >
              Home
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/about-us"
              className="text-base leading-6 text-red-500 hover:text-red-900"
            >
              About
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/contact"
              className="text-base leading-6 text-red-500 hover:text-red-900"
            >
              Contact
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/faq"
              className="text-base leading-6 text-red-500 hover:text-red-900"
            >
              FAQ
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/gallery"
              className="text-base leading-6 text-red-500 hover:text-red-900"
            >
              Gallery
            </Link>
          </div>
          <div className="px-5 py-2">
            <Link
              to="/courses"
              className="text-base leading-6 text-red-500 hover:text-red-900"
            >
              Courses
            </Link>
          </div>
        </nav>
        <div className="mt-8 flex justify-center">
          <a
            href="http://Fb.me/Savageoutdoordefense"
            className="text-red-400 text-3xl hover:text-red-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only max-w-xs">Facebook</span>
            <FontAwesomeIcon icon={faFacebookSquare} />
          </a>
          <a
            href="https://instagram.com/savage.outdoor.defense?igshid=195ehpl2eiuzb"
            target="_blank"
            rel="noopener noreferrer"
            className="ml-6 text-3xl text-red-400 hover:text-red-500"
          >
            <span className="sr-only">Instagram</span>
            <FontAwesomeIcon icon={faInstagram} />
          </a>
          <a
            href="https://twitter.com/SavageOutAndDef"
            className="ml-6 text-3xl text-red-400 hover:text-red-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">Twitter</span>
            <FontAwesomeIcon icon={faTwitterSquare} />
          </a>
          <a
            href="https://www.youtube.com/channel/UCnQ9Q8wu8lRKO2QMbdLQk0g"
            className="ml-6 text-3xl text-red-400 hover:text-red-500"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="sr-only">YouTube</span>
            <FontAwesomeIcon icon={faYoutubeSquare} />
          </a>
        </div>
        <div className="mt-8">
          <p className="text-center text-base leading-6 text-gray-400">
            © {new Date().getFullYear()} Savage Outdoor & Defense, LLC. All
            rights reserved.
            <span className="text-red-500">
              {" "}
              Developed by{" "}
              <a
                href="https://www.linkedin.com/in/blake-enloe/"
                class="text-blue-500"
                title="web development by Blake Enloe"
                target="_blank"
              >
                Blake Enloe
              </a>
            </span>
          </p>
        </div>
      </div>
    </div>
  )
}

export default Footer
